import React, { useMemo } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'

import { Text } from '../../atoms'
import { PostCard } from '../../molecules'

import convertToExcerpt from '../../../utils/convertToExcerpt'

import * as styles from './post-list.module.scss'

type Props = {
  data: Queries.PostsQuery
}

const PostList: React.FC<Props> = (props) => {
  const { data } = props

  const postGroups = useMemo(() => {
    const posts = data.allContentfulPost.edges.map((edge) => edge.node)
    const _postGroups = []
    for (let i = 0; i < posts.length; i += 6) {
      _postGroups.push(posts.slice(i, i + 6))
    }
    return _postGroups
  }, [data.allContentfulPost.edges])

  return (
    <section className={styles.articlesSection}>
      <Text as="h2" textType="subTitle" style={{ marginBottom: '12px' }}>
        ARTICLES
      </Text>
      {postGroups.map((pg, i) => (
        <div
          key={`postGroup-${i}`}
          className={cx(styles.listContainer, i % 2 === 1 && styles.second)}
        >
          <div className={cx(styles.listGrid, 'container')}>
            {pg.map((post) => (
              <Link
                key={`blog-${post.id}`}
                className={styles.postCardLink}
                to={`/blog/${post.slug}/`}
              >
                <PostCard
                  className={styles.postCard}
                  title={post.title || ''}
                  description={convertToExcerpt(post.content?.childMarkdownRemark?.excerpt || '')}
                  thumbnail={post.thumbnail?.id || 'no-img.jpg'}
                  date={post.createdAt || ''}
                  timeToRead={
                    post.content?.childMarkdownRemark?.timeToRead
                      ? post.content?.childMarkdownRemark?.timeToRead * 2
                      : 3
                  }
                />
              </Link>
            ))}
          </div>
        </div>
      ))}
    </section>
  )
}

export default PostList
