import React, { useState, useEffect, useCallback } from 'react'

import { DynamicImage, Icon } from '../../atoms'

import parseHTML from '../../../utils/parseHTML'

import * as styles from './slideshow.module.scss'

type Props = {
  title?: string
  image?: string
  html: string
  onClickClose: () => void
}

const Slideshow: React.FC<Props> = (props) => {
  const { html, onClickClose, title, image } = props

  const htmlBlocks = parseHTML(html)

  const [slideNum, setSlideNum] = useState(0)
  const moveSlide = useCallback(
    (e: KeyboardEvent) => {
      const key = e.key
      switch (key) {
        case 'Left':
        case 'ArrowLeft':
          setSlideNum((num) => (num - 1 < 0 ? 0 : num - 1))
          break
        case 'Right':
        case 'ArrowRight':
          setSlideNum((num) => (num === htmlBlocks.length ? htmlBlocks.length : num + 1))
          break
        case 'Esc':
        case 'Escape':
          onClickClose()
          break
      }
    },
    [onClickClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', moveSlide)
    return () => {
      document.removeEventListener('keydown', moveSlide)
    }
  }, [])

  let h2: string | null = null
  let h3: string | null = null
  const slides: JSX.Element[] = []
  for (const i in htmlBlocks) {
    const block = htmlBlocks[i]

    if (block.type === 'h2') {
      h2 = block.content
      h3 = null
    }
    if (block.type === 'h3') {
      h3 = block.content
    }
    const dom = (
      <div key={i} className={styles.slideBox}>
        <div className={styles.slideHead}>
          {h2 && <div className={styles.indexTitle} dangerouslySetInnerHTML={{ __html: h2 }} />}
          {h3 && (
            <>
              <span className={styles.arrow} />
              <div className={styles.indexTitle} dangerouslySetInnerHTML={{ __html: h3 }} />
            </>
          )}
        </div>
        <div className={styles.slideContent} dangerouslySetInnerHTML={{ __html: block.content }} />
      </div>
    )
    slides.push(dom)
  }

  return (
    <div className={styles.slideContainer}>
      <div className={styles.viewBox}>
        <button className={styles.closeButton} onClick={onClickClose}>
          <Icon className={styles.closeIcon} size={32} color="#949494" icon="close" />
        </button>
        <div className={styles.slideWrapper} style={{ left: `${-slideNum * 100}%` }}>
          <div className={styles.slideBox}>
            {image && (
              <DynamicImage className={styles.thumbnail} filename={image} alt="thumbnail" />
            )}
            {title && <h1 className={styles.title}>{title}</h1>}
          </div>
          {slides}
        </div>
        <div className={styles.slideNum}>{`${slideNum + 1}/${slides.length + 1}`}</div>
      </div>
    </div>
  )
}

export default Slideshow
