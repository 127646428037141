import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout, SEO, TopImageBox } from '../components/organisms/common'
import { PostList } from '../components/organisms/blog'

type Props = {
  data: Queries.PostsQuery
} & PageProps

const Blog: React.FC<Props> = ({ data }) => {
  return (
    <Layout path="/blog/">
      <TopImageBox filename="blog.jpeg" alt="blog top image" title="Blog" />
      <PostList data={data} />
    </Layout>
  )
}

export default Blog

export const Head = () => <SEO />

export const pageQuery = graphql`
  query Posts {
    allContentfulPost(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          slug
          title
          createdAt(formatString: "YYYY/MM/DD")
          thumbnail {
            id
            title
            file {
              url
            }
          }
          content {
            childMarkdownRemark {
              excerpt(format: HTML)
              timeToRead
            }
          }
        }
      }
    }
  }
`
