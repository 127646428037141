import React from 'react'
import cx from 'classnames'

import { DynamicImage, Text, Button } from '../../atoms'
import Toc from './toc'

import * as styles from './side-container.module.scss'

type Props = {
  tableOfContents: string
  className?: string
}

const SideContainer: React.FC<Props> = (props) => {
  const { tableOfContents, className } = props

  return (
    <div className={cx(styles.sideContainer, className)}>
      <div className={styles.sidePanel}>
        <h3 className={styles.sideTitle}>Profile</h3>
        <div className={styles.inner}>
          <div className={styles.roundPhotoFrame}>
            <DynamicImage width={80} height={80} filename="/chatran.jpg" alt="author picture" img />
          </div>
          <Text as="p" size="l" className={styles.name}>
            Mitsuru Takahashi
          </Text>
          <p className={styles.profileText}>
            京都市内にてフリーランスエンジニアとして活動しています。
          </p>
          <Button
            className={styles.detailButton}
            size="s"
            shape="round"
            buttonType="link"
            linkTo="/about/"
          >
            detail
          </Button>
        </div>
      </div>
      <div className={cx(styles.sidePanel, styles.toc)}>
        <h3 className={styles.sideTitle}>Index</h3>
        <div className={styles.inner}>
          <Toc tableOfContents={tableOfContents} />
        </div>
      </div>
    </div>
  )
}

export default SideContainer
