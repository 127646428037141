import React from 'react'
import cx from 'classnames'

import * as styles from './toc.module.scss'

type Props = {
  tableOfContents: string
  className?: string
}

const Toc: React.FC<Props> = ({ tableOfContents, className }) => {
  return (
    <div
      className={cx(styles.toc, className)}
      dangerouslySetInnerHTML={{ __html: tableOfContents }}
    ></div>
  )
}

export default Toc
