import React from 'react'
import { Link } from 'gatsby'

import { DynamicImage } from '../../atoms'

import * as styles from './bottom-profile.module.scss'

const BottomProfile: React.FC = () => {
  return (
    <div className={styles.bottomProfile}>
      <div className={styles.roundPhotoFrame}>
        <DynamicImage width={80} height={80} filename="/chatran.jpg" alt="author picture" img />
      </div>
      <div className={styles.profileBox}>
        <p className={styles.name}>Mitsuru Takahashi</p>
        <p className={styles.profileText}>
          京都市内にてフリーランスエンジニアとして活動しています。
        </p>
        <Link to="/about" className={styles.detailButton}>
          detail
        </Link>
      </div>
    </div>
  )
}

export default BottomProfile
